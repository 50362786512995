









































import {Component, Vue} from 'vue-property-decorator';
import {EAD_DTO} from "@/dto/person/EAD_DTO";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {PERSON_ID} from "@/constants/Paths";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import AddEADPayload from "@/dto/person/AddEADPayload";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex, StateIDSubtype} from "@/constants/PersonConstants";
import Workspaces from "@/state/Workspaces";
import {ifValid} from "@/utils/ComponentUtils";
import StateIdDTO from "@/dto/person/StateIdDTO";
import AddStateIDPayload from "@/dto/person/AddStateIDPayload";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import StateId from "@/components/profile/person/StateId.vue";

const AppModule = namespace("App");


@Component({
  computed: {
    StateIDSubtype() {
      return StateIDSubtype
    },
    PersonSex() {
      return PersonSex
    }
  },
  components: {StateId, PortalAddress, PortalDate, PortalInput, SingleFileHolder, PortalCollapse}
})
export default class StateIdList extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  addMode = false;

  documents: Array<StateIdDTO> = [];

  payload = new AddStateIDPayload();

  mounted(){
    this.loadDocuments();
  }

  loadDocuments(){
    this.startLoading();
    PersonService.getStateId(this.personId).then(
        ok => {
          this.documents = ok.data.sort((a, b) => new Date(b.expirationDate).getTime() - new Date(a.expirationDate).getTime());
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    )
  }

  get personId(){
    return Workspaces.getCurrent.id;
  }

  addDocument(){
    ifValid(this, () => {
      this.startLoading();
      this.payload.personId = this.personId;
      PersonService.addStateId(this.payload).then(
          ok => {
            this.payload = new AddStateIDPayload();
            this.addMode = false;
            this.stopLoading();
            this.loadDocuments();
          },
          error => {
            this.stopLoading();
            console.log(JSON.stringify(error))
          }
      )
    })
  }

}

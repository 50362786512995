import {PersonSex, StateIDSubtype} from "@/constants/PersonConstants";
import AddressDTO from "@/dto/AddressDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class AddStateIDPayload {
    personId!: number;
    state: string | null = null;
    subtype: StateIDSubtype | null = null;
    documentNumber!: string;
    lastName!: string;
    firstName!: string;
    address: AddressDTO = new AddressDTO();
    dateOfBirth!: Date;
    sex!: PersonSex;
    issueDate!: Date;
    expirationDate!: Date;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;
    otherNotes: string | null = null;

    constructor(init?: Partial<AddStateIDPayload>) {
        Object.assign(this, init);
    }
}